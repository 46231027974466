import React from 'react'
import slugify from '../utils/slugify'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import References from '../components/sections/references'
import Hero from '../components/sections/hero'
import ProductDescription from '../components/sections/products-description'
import ContactForm from '../components/sections/contact-form'

const DevelopPage = (props) => {
  const { t } = useTranslation('gatsby-experts')

  const PRODUCTS = React.useMemo(() => {
    const productsArray = t('products')
    return productsArray.map(product => ({ ...product, slug: slugify(product.title) }))
  }, [])

  return (
    <Layout
      seo={{
        title: t('meta').title,
        description: t('meta').description
      }}
      pageProps={props}
    >
      <Hero
        title={t('h1')}
        subtitle={t('h1_small')}
        products={PRODUCTS}
      />


      <ProductDescription
        title={t('contact_us_for')}
        products={PRODUCTS}
        cta={t('contact_us')}
      />
      <References
        title={t('references')}
      />

      <ContactForm
        tagline={t('our_job')}
        country={t('belgium')}
      />
    </Layout>
  )
}

export default DevelopPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`